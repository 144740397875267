import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';

import { useRef } from 'react';

import {
  useAuth,
  useUser
} from "@clerk/clerk-react";

const environment = process.env.REACT_APP_ENVIRONMENT; //prod or dev

let MAIN_API_NAME = "song_cover_ai_1"

let MAIN_API_URL = "https://funquiz.app/modular2/song-cover-ai-1"

function UserEmailAddress() {
  const { isLoaded, isSignedIn, user } = useUser();

  if (!isLoaded || !isSignedIn) {
    return "";{/*null;*/}
  }
  return user.primaryEmailAddress.emailAddress;
}

function FxInputForm3() {
    const [fileURL1, setFileURL1] = useState(null);
    const [fileURL2, setFileURL2] = useState(null);
    
    const audioRef1 = useRef();
    const audioRef2 = useRef();
    const updateSong1 = () => { // reload the song, important or the current song won't change
      if(audioRef1.current){
        audioRef1.current.pause();
        audioRef1.current.load();
      }
    }
    const updateSong2 = () => { // reload the song, important or the current song won't change
      if(audioRef2.current){
        audioRef2.current.pause();
        audioRef2.current.load();
      }
    }

    const [selectedFile1, setSelectedFile1] = useState(null);
    const handleFileChange1 = (event) => {
      if (event.target.files.length > 0)
      {
        setSelectedFile1(event.target.files[0]);
        setFileURL1(URL.createObjectURL(event.target.files[0]));
        console.log("change current file 1");
        console.log(fileURL1);
        updateSong1();
      }
    };

    //const authenticatedFetch = useFetch();
    const { getToken } = useAuth();
    const emailAddr = UserEmailAddress();

    const [textOutput, setTextOutput] = useState('');

    const inputProps = {
      inputProps: {
        style: { textAlign: 'center' },
      },
    };
  
    const handleTextOutputChange = event => {
        {/*console.log('Saving value');
    console.log(event.target.value);*/}
        setTextOutput(event.target.value);
    };


    const [textInput1, setTextInput1] = useState('');
    const handleTextInputChange1 = event => {
        {/*console.log('Saving value');
    console.log(event.target.value);*/}
        setTextInput1(event.target.value);
    };

    const [textInput2, setTextInput2] = useState('');
    const handleTextInputChange2 = event => {
        {/*console.log('Saving value');
    console.log(event.target.value);*/}
        setTextInput2(event.target.value);
    };
  
    const inputProps1 = {
      inputProps: {
        style: { textAlign: 'center' },
      },
    };



    let audioElement0;
    if (fileURL1 == null)
    {
      audioElement0 = <p></p>;
    }
    else
    {
      audioElement0 = <audio controls ref={audioRef1}><source src={fileURL1} type="audio/mpeg"></source></audio>;
    }

    let audioElement;
    let successMessage = ""
    if (textOutput == "")
    {
      audioElement = <p></p>;
    }
    else if (isValidHttpUrl(textOutput))
    {
      successMessage = <p>{"Success!!! Output below!"}</p>;
      audioElement = 
        <audio controls ref={audioRef2}>
          <source src={textOutput} type="audio/mpeg"></source>
        </audio>;
    }
    else {
      /* console.log("greet2 $textOutput"); */
      audioElement = <p>{textOutput}</p>;
    }
    
    function handleUpload() {
      setTextOutput("Loading...");
      // Implement upload logic here, e.g., using Fetch API or a library like Axios
      if (selectedFile1) {
        const formData = new FormData();
        formData.append('sound1', selectedFile1);
        formData.append('rvc_name', textInput1);

        formData.append('email', emailAddr);
        formData.append('env', environment);
        formData.append('api_name',MAIN_API_NAME)
        // console.log(selectedFile);
  
        const fetchX = async () => {
          const myToken = await getToken();
          // Make an API request to upload the file
          fetch(MAIN_API_URL, {
            mode: 'cors',
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${myToken}`,
            },
            body: formData,
          })
          .then(response => response.json())
          .then(response => {
            // Handle response from server
            console.log(response["message"]);
            setTextOutput(response["message"]);
          })
          .catch(error => {
            // Handle error
            setTextOutput("Sorry, action failed");//error.message);
            console.error(error);
            //console.log("error")
          });
        };
        fetchX();
      }
    }

    return (
      <div>
        <Box display="flex" 
             marginTop={5} 
             alignItems="center"
             justifyContent="center">
        {/*<p> {textOutput} </p>*/}
        </Box>
        <Box display="flex" 
             marginTop={5} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <p>Choose a source audio song file</p>
        </Box>
        <Box display="flex" 
             marginTop={2} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <input type="file" accept="audio/*" onChange={handleFileChange1} />
        {audioElement0}
        {/*<button onClick={handleUpload}>Upload</button>*/}
        </Box>
        <Box display="flex" 
             marginTop={5} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <TextField 
          id="outlined-basic" 
          label="RVC name e.g. 'Squidward'..." 
          variant="outlined" 
          InputProps={inputProps}
          value={textInput1}
          multiline
          onChange={handleTextInputChange1}/>
        </Box>
        <Box display="flex" 
             marginTop={5} 
             marginBottom={5} 
             alignItems="center" 
             bgcolor="white"
             justifyContent="center">
        <Button variant="contained"
                onClick={handleUpload}>Submit Input</Button>
        </Box>
        <Box display="flex" marginTop={5} marginBottom={0} justifyContent="center">
        <p>{successMessage}</p>
        </Box>
        <Box display="flex" 
             marginTop={0} 
             marginBottom={20} 
             justifyContent="center"
             alignItems="center" > 
             {/* justyfyContent seems to be the key way*/}
             
        {audioElement}
        </Box>
        {/*<Box marginTop={0} marginBottom={5} justifyContent="center">
        <div
          style={{
            whiteSpace: "pre-line", // Preserve line breaks and spaces
            height: "300px",       // Set a fixed height
            overflow: "auto",      // Enable scrolling if content overflows
            border: "1px solid #ccc",
            padding: "10px",
          }}
        > {textOutput} </div>
        </Box>*/}
      </div>
    );
  }

  function isValidHttpUrl(string) {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }

  export default FxInputForm3;